/*eslint-disable*/
import React from "react";
import {
  Grid,
  Box,
  Container,
  Button,
  Modal,
  Typography,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DashboardSidebar from "@components/DashboardSidebar/DashboardSidebar";
import StaffNavbar from "./StaffNavbar";
import SearchInput from "@components/SearchInput";
import MUIDataTable from "mui-datatables";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import UserSignUp from "@service/UserSignUp";
import {
  handleErrorMessage,
  messageAlert,
  storeLocalStorage,
} from "@utils/helpers/functions";
import SettingsNav from "@components/Settings/SettingsNav";
import useGetUserData from "@utils/hooks/useGetUserData";
import { useNavigate } from "react-router-dom";
import { getRegions } from "@service/CommonApi";
import { getDistricts } from "@service/CommonApi";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TableLoaders from "@components/Loaders/TableLoaders";

const responsive = "vertical";
const tableBodyHeight = `400px`;
const tableBodyMaxHeight = "400px";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
};
const PRIMARY_COLOR = "#2a3b8f";

const returnCapitalizedInitial = (name) => {
  let initial = "";
  if (name !== undefined && name !== null) {
    initial = name.charAt(0).toUpperCase();
  }
  return initial;
};

const StaffManagement = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const { userData, isLoading } = useGetUserData();
  const [_isLoading, setIsLoading] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [formModal, setModalForm] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [regions, setRegions] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const navigate = useNavigate();
  const [showDisabled, setShowDisabled] = React.useState(false);
  let [applicantData, setApplicantData] = React.useState({
    page: 0,
    count: 1,
    data: [],
  });
  const columns = [
    "No.",
    {
      name: "Name",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              px={"0.9rem"}
              py={"0.6rem"}
              sx={{
                backgroundColor: PRIMARY_COLOR,
                color: "white",
                borderRadius: "50%",
              }}
            >
              {returnCapitalizedInitial(value)}
            </Box>
            <Box sx={{ marginLeft: "1rem" }}>{value}</Box>
          </Box>
        ),
      },
    },
    "Phone Number",
    "Email Address",
    "Role",
    "District",
    "Region",
    {
      name: "Action",
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Button
            sx={{ textTransform: "initial" }}
            onClick={() => {
              navigate(`/admin/details/${value}`);
            }}
          >
            View
          </Button>
        ),
      },
    },
  ];
  const [tableData, setTableData] = React.useState([]);

  const onSubmit = async (data) => {
    try {
      let params = {};
      params = data;
      if (data.staff_type == "bac_regional_head") {
        const { district, ...rest } = data;
        params = rest;
        params.region = parseInt(params.region);
      } else if (data.staff_type == "head_office") {
        const { region, district, ...rest } = data;
        params = rest;
      } else if (data.staff_type == "bac") {
        params.region = parseInt(params.region);
        params.district = parseInt(params.district);
      }

      params.date_of_birth = "2022-01-01";
      params.phone_numher = "00000000";
      await UserSignUp.createStaff(params);
      messageAlert("success", "Staff Account Created");
      await getStaff();
      setModalForm(false);
      reset();
    } catch (e) {
      let message = handleErrorMessage(e);
      if (message == "d") {
        messageAlert(
          "error",
          "Please make sure your email address has not been used already."
        );
      } else {
        messageAlert("error", message);
      }

    }
  };

  const getRoles = async () => {
    setIsLoading(true);
    try {
      let { data } = await UserSignUp.getRoles();
      setRoles(data);
    } catch (e) {
      handleErrorMessage(e);

    }
    setIsLoading(false);
  };
  const returnDefaultValue = (value) => {
    if (value != null && value != undefined) {
      return value;
    }
    return "None";
  };

  const getStaff = async () => {
    setIsLoading(true);
    try {
      let { data } = await UserSignUp.getStaff(
        applicantData?.page,
        searchString
      );
      let tableData = [];
      data?.data?.reverse().forEach((admin, index) => {
        let { first_name, last_name, phone_number, email, id } = admin.user;
        let { name: role_name } = admin.role;
        if (admin?.enabled == true) {
          let tableRow = [
            index + 1,
            `${first_name} ${last_name}`,
            phone_number,
            email,
            role_name,
            returnDefaultValue(admin?.profile?.region?.name),
            returnDefaultValue(admin?.profile?.district?.name),
            id,
          ];

          tableData.push(tableRow);
        }
      });
      setApplicantData({
        ...applicantData,
        count: data?.count,
        data: tableData,
      });
      setTableData(tableData);
    } catch (e) {
      handleErrorMessage(e);

    }
    setIsLoading(false);
  };

  const get_regions = async () => {
    try {
      let { data } = await getRegions();
      setRegions(data);
    } catch (e) {

    }
  };

  const get_districts = async (region) => {
    try {
      let { data } = await getDistricts(region);
      setDistricts(data);
    } catch (e) {
      handleErrorMessage(e);

    }
  };

  React.useEffect(() => {
    document.body.style.cssText = "overflow-y:auto!important";
    getRoles();
    getStaff();
    get_regions();
  }, []);

  const buttonStyle = {
    "&:hover": { color: "#2a3b8f", border: "1px solid #2a3b8f" },
    fontWeight: "400",
    fontFamily: "inherit !important",
    fontSize: "0.85rem",
    color: "white",
    backgroundColor: "#2a3b8f",
    textTransform: "initial !important",
    padding: "0.7rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    marginLeft: "2rem",
  };

  React.useEffect(() => {
    console.log(userData,27000)
    if (!isLoading){
    let status = userData?.staff_type == "super_admin" || userData?.role?.name == 'super_admin';
    if (status == false) {
      navigate("/404");
    }
    }

  }, [userData]);

  React.useEffect(() => {
    getStaff();
  }, [applicantData?.page]);

  const tableOptions = {
    fixedHeader: true,
    fixedFooter: true,
    print: false,
    search: false,
    viewColumns: false,
    download: false,
    filter: false,
    filterType: "dropdown",
    responsive,
    tableBodyHeight: `${window.innerHeight - window.innerHeight * 0.05}px`,
    tableBodyMaxHeight,
    searchText: searchString,
    page: applicantData?.page,
    serverSide: true,
    count: applicantData?.count,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],

    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setApplicantData({ ...applicantData, page: tableState?.page });
      }
    },
    customSearchRender: () => null,
  };

  const handleTabChange = (event, value) => {
    if (value == 0) {
      navigate("/settings/roles");
    }
  };

  const staffType = watch("staff_type") ? watch("staff_type") : "";

  return (
    <>
      <Modal
        open={formModal}
        onClose={() => {
          setModalForm(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-content" sx={modalStyle}>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box sx={{ fontWeight: "700", fontSize: "1rem", display: "flex" }}>
              <Box>Add New User</Box>
            </Box>
            <Box sx={{ order: "1", marginLeft: "auto" }}>
              <button
                style={{
                  backgroundColor: "none",
                  border: "none",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  setModalForm(false);
                }}
              >
                <CloseIcon sx={{ paddingTop: "0.1rem" }} />
              </button>
            </Box>
          </Box>
          <form id="create-account" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ marginTop: "1rem" }}>
              <Grid
                container
                sx={{ width: "100%" }}
                rowSpacing={"1rem"}
                columnSpacing={"1rem"}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box>First Name</Box>
                  <Box mt={"1rem"}>
                    <input
                      {...register("first_name", { required: true })}
                      type="text"
                      placeholder="name"
                      className="add-staff-form-input"
                    ></input>
                  </Box>
                  {errors.first_name && <span>This field is required</span>}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box>Last Name</Box>
                  <Box mt={"1rem"}>
                    <input
                      {...register("last_name", { required: true })}
                      type="text"
                      placeholder="name"
                      className="add-staff-form-input"
                    ></input>
                  </Box>
                  {errors.last_name && <span>This field is required</span>}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box>Email</Box>
                  <Box mt={"1rem"}>
                    <input
                      {...register("email", { required: true })}
                      type="email"
                      placeholder="email"
                      className="add-staff-form-input"
                    ></input>
                  </Box>
                  {errors.email && <span>This field is required</span>}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box>Gender</Box>
                  <Box mt={"1rem"}>
                    <select
                      {...register("gender", { required: true })}
                      type="text"
                      style={{ width: "100%" }}
                      className="add-staff-form-input"
                    >
                      <option value={""}></option>
                      <option value={"M"}>Male</option>
                      <option value={"F"}>Female</option>
                    </select>
                  </Box>
                  {errors.gender && <span>This field is required</span>}
                </Grid>
                <Grid item xs={6}>
                  <Box>User Role</Box>
                  <Box mt={"1rem"}>
                    <select
                      {...register("role", { required: true })}
                      type="text"
                      style={{ width: "100%" }}
                      className="add-staff-form-input"
                    >
                      {roles.map((role) => {
                        return <option value={role.id}>{role.name}</option>;
                      })}
                    </select>
                  </Box>
                  {errors.role && <span>This field is required</span>}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Box>Type</Box>
                  <Box mt={"1rem"}>
                    <select
                      {...register("staff_type", { required: true })}
                      type="text"
                      style={{ width: "100%" }}
                      className="add-staff-form-input"
                    >
                      <option value={""}></option>
                      <option value={"bac"}>BAC User</option>
                      <option value={"bac_regional_head"}>
                        Regional Head User
                      </option>
                      <option value={"head_office"}>Head office user</option>
                    </select>
                  </Box>
                  {errors.staff_type && <span>This field is required</span>}
                </Grid>
                {(staffType === "bac" || staffType === "bac_regional_head") && (
                  <>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box>Region</Box>
                      <Box mt={"1rem"}>
                        <select
                          {...register("region", { required: true })}
                          type="text"
                          style={{ width: "100%" }}
                          className="add-staff-form-input"
                          onChange={async (e) => {
                            await get_districts(e.target.value);
                          }}
                        >
                          <option value={""}>{""}</option>
                          {regions.map((region, index) => {
                            return (
                              <option
                                value={region?.id}
                                key={`${region?.id}-${index}`}
                              >
                                {region?.name}
                              </option>
                            );
                          })}
                        </select>
                      </Box>
                      {errors.region && <span>This field is required</span>}
                    </Grid>
                  </>
                )}
                {staffType === "bac" && (
                  <>
                    <Grid
                      item
                      xs={6}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Box>District</Box>
                      <Box mt={"1rem"}>
                        <select
                          {...register("district", { required: true })}
                          type="text"
                          style={{ width: "100%" }}
                          className="add-staff-form-input"
                        >
                          <option value={""}>{""}</option>
                          {districts.map((district, index) => {
                            return (
                              <option
                                value={district?.id}
                                key={`${district?.id}-${index}`}
                              >
                                {district?.name}
                              </option>
                            );
                          })}
                        </select>
                      </Box>
                      {errors.district && <span>This field is required</span>}
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </form>
          <Box mt={"1rem"} sx={{ width: "100%", display: "flex" }}>
            <Box sx={{ display: "flex", order: "0", marginLeft: "auto" }}>
              <Box>
                <Button
                  sx={{
                    fontFamily: "inherit !important",
                    fontSize: "0.85rem",
                    textTransform: "initial !important",
                    color: "#2a3b8f",
                    padding: "0.7rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                    border: "1px solid #2a3b8f",
                    marginRight: "2rem",
                  }}
                  onClick={() => {
                    setModalForm(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  form="create-account"
                  type="submit"
                  sx={{
                    "&:hover": {
                      color: "#2a3b8f",
                      border: "1px solid #2a3b8f",
                    },
                    fontWeight: "400",
                    fontFamily: "inherit !important",
                    fontSize: "0.85rem",
                    color: "white",
                    backgroundColor: "#2a3b8f",
                    textTransform: "initial !important",
                    padding: "0.7rem",
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                  }}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Box className="staff-main" sx={{ width: "100%", overflowY: "hidden" }}>
        <Grid sx={{ width: "100%", display: "flex" }}>
          <ApplicantSidebar selectedView={3} />
          <Grid sx={{ display: "flex", flexDirection: "column", width: "98%" }}>
            <StaffNavbar user={userData?.user} sx={{ height: "100%" }} />
            <Grid container>
              <SettingsNav selectedView={0} />
              <Grid xs={9} sx={{ overflowY: "auto", height: "100%" }}>
                <Box
                  sx={{
                    padding: "2rem",
                    paddingTop: "3rem !important",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Tabs
                      className="custom-tabs"
                      value={1}
                      onChange={handleTabChange}
                      style={{
                        borderBottom: "0.5px solid #EBEFF2",
                        borderTop: "0.5px solid #EBEFF2",
                      }}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Roles" value={0} />
                      <Tab label="User Profile" value={1} />
                    </Tabs>
                  </Box>

                  <Box mt={"2rem"} sx={{ display: "flex" }}>
                    <Box sx={{ display: "flex", width: "45%" }}>
                      <SearchInput setSearchQuery={setSearchString} />
                      <Button onClick={getStaff} sx={buttonStyle}>
                        Filter
                      </Button>
                    </Box>
                    <Box
                      className="add-staff-btn"
                      sx={{
                        backgroundColor: "#2a3b8f",
                        order: "1",
                        marginLeft: "auto",
                        display: "flex",
                        alignItems: "center",
                        width: "22%",
                        borderRadius: "0.5rem",
                        justifyContent: "center",
                        height: "50px",
                      }}
                      onClick={() => {
                        setModalForm(true);
                      }}
                    >
                      <Box>
                        <AddIcon
                          sx={{
                            color: "white",
                            marginRight: "0.4rem",
                            marginTop: "0.1rem",
                          }}
                        />
                      </Box>
                      <Box>
                        <span style={{ color: "white", fontSize: "0.9rem" }}>
                          Add New User
                        </span>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    className="custom-data-table settings-table"
                    my={"2rem"}
                    sx={{ width: "100%" }}
                  >
                    {_isLoading == true ? (
                      <TableLoaders />
                    ) : (
                      <MUIDataTable
                        options={tableOptions}
                        columns={columns}
                        data={tableData}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StaffManagement;
