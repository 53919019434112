import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT || 'https://stagingapi.ghjobsandskills.top/api';
export const BASE_CLIENT = process.env.REACT_APP_CLIENT_URL || 'https://jnstagingadmin.gheaonline.com';
import { getLocalStorage } from '@utils/helpers/functions';

const postRequest = (url, data) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    },
  };

  return axios.post(`${BASE_URL}/${url}`, data, config);
};

const patchRequest = (url, data) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    },
  };

  return axios.patch(`${BASE_URL}/${url}`, data, config);
};

const getRequestWithData = (url, data) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    },
  };

  return axios.get(`${BASE_URL}/${url}?${data}`, config);
};

const getRequest = (url, params) => {
  return axios({
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    },
    url: `${BASE_URL}/${url}`,
  });
};

const deleteRequest = (url) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    },
  };

  return axios.delete(`${BASE_URL}/${url}`, config);
};

const putRequest = (url, params) => {
  let config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getLocalStorage('accessToken')}`,
    },
  };

  return axios.put(`${BASE_URL}/${url}`, params, config);
};

export default {
  BASE_URL,
  BASE_CLIENT,
  postRequest,
  getRequest,
  patchRequest,
  deleteRequest,
  getRequestWithData,
  putRequest,
};
