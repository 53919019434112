/*eslint-disable*/
import React from 'react';
import { IFilterParams } from '@/utils/interfaces/approvals.interface';
import { useQuery } from 'react-query';
import { getTrainingApprovals } from '@/service/Applicants';
import { MUIDataTableColumnDef, MUIDataTableOptions, MUIDataTableColumnOptions } from 'mui-datatables';
import { AvatarInitial } from '@/utils/Logo';
import { AxiosResponse } from 'axios';
import {
  useGenerateDisbursementCSV,
  useGeneratePreDisbursementCSVPrint,
  useGetPredisbursementTableData,
} from '../hooks/disbursementTable.hooks';
import { useGetDistricts, useGetRegions } from '@/utils/hooks/getRegionDistricts';
import { IPredisbursementParams } from '../interfaces/DisbursementBaseLayout.interface';
import apiConfig from '@/service/apiConfig';
import { handleErrorMessage, messageAlert, parseIdFromProjectId } from '@/utils/helpers/functions';
import { Button } from '@mui/material';
import { Navigator, useNavigate } from 'react-router-dom';

const tableBodyHeight = `${window.innerHeight * 0.95}px`;
const tableBodyMaxHeight = '';

const getPredisbursementassessmentlist = async (props: IPredisbursementParams) => {
  const keys: Array<keyof IPredisbursementParams> = Object.keys({
    page: 0,
    region: undefined,
    district: undefined,
    q: undefined,
    score_lower_limit: 0,
  } as IPredisbursementParams) as Array<keyof IPredisbursementParams>;
  let queryString = '';
  keys.forEach((key) => {
    if (props[key] && key !== 'page') {
      queryString = queryString + `&${key}=${props[key]}`;
    }
  });
  return apiConfig.getRequest(
    `staff/approvals/pre_disbursement_list?page=${(props?.page || 0) + 1}&per_page=10${queryString}`,
  );
};

export const useGetPredisbursement = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [params, setParams] = React.useState<IFilterParams>({
    tableData: [],
    age_lower_limit: '',
    age_upper_limit: '',
    disability: '',
    district: '',
    educational_level: '',
    gender: '',
    q: '',
    region: '',
    score_lower_limit: '',
    score_upper_limit: '',
    vocational_skill: '',
    pass_threshold: 0,
  });
  const { data, isLoading, isRefetching, error, refetch } = useQuery(
    'training-list',
    () => {
      return getPredisbursementassessmentlist({
        district: params.district,
        region: params.region,
        page: currentPage,
        q: params.q,
        score_lower_limit: parseInt(params.score_lower_limit),
      });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  );
  const { regions, error: regionsError } = useGetRegions();
  const { districts, error: districtsError } = useGetDistricts(regions, parseInt(params?.region || '1'));

  const _data = data as unknown as AxiosResponse<IApprovalsList>;

  const predisbursementTableOptions: MUIDataTableOptions = {
    print: false,
    search: false,
    viewColumns: false,
    download: true,
    filter: false,
    filterType: 'dropdown',
    responsive: 'vertical',
    serverSide: true,
    page: currentPage,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    count: count,
    tableBodyHeight,
    tableBodyMaxHeight,
    sortOrder: {
      name: 'Role',
      direction: 'asc',
    },
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setCurrentPage(tableState?.page);
      }
    },

    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      const dataToState = rowsSelected?.map((item) => {
        return item;
      });
      setSelectedItems(getSelectedRows(dataToState || []));
    },
  };

  const predisbursementTableColumns: MUIDataTableColumnDef[] = [
    {
      name: 'Application ID',
      options: {
        filter: false,
        customBodyRender: (value: any) => <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{value}</div>,
      },
    },
    {
      name: 'Full Name',
      options: {
        filter: false,
        customBodyRender: (value: any) => <AvatarInitial text={value} />,
      },
    },
    {
      name: 'Business Name',
    },
    // { name: 'Sector' },
    // { name: 'Type of Business' },
    { name: 'Region' },
    { name: 'District' },
    { name: 'Phone Number' },
    {
      name: 'Grant Amount Requested',
    },
    {
      name: 'Applicant Total Score',
    },
    {
      name: 'Action',
      options: {
        customBodyRender: (id: any) => {
          return (
            <div>
              <Button
                onClick={() => {
                  navigate(`/applicants/${parseIdFromProjectId(id)}`);
                }}
              >
                View Details
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const getTableData = (data: AxiosResponse<IApprovalsList, any>) => {
    let { tableData, count } = useGetPredisbursementTableData(data);
    setCount(count);
    setTableData(tableData);
    // props?.setBaseLayoutParams({ ...params, tableData: tableData });
  };

  const getSelectedRows = (rowArray: any[]) => {
    try {
      let rowDataArray: any = [];
      rowArray.forEach((rowIndex: string | number | any) => {
        let _applicantData = tableData[rowIndex];
        rowDataArray.push(_applicantData[0]); //this index is where the id is stored
      });
      return rowDataArray;
    } catch (e) {
      return [];
    }
  };

  const filter = () => {
    if (currentPage > 0) {
      setCurrentPage(0);
    } else {
      refetch();
    }
  };

  const downloadCSV = () => {
    const headerColumns = [
      'Application ID',
      'Account Number',
      'Bank Name',
      // 'Sector',
      // 'Type of Business',
      'Branch Name',
      'Date of Payment',
      'Disbursed Amount',
    ];
    const { success } = useGeneratePreDisbursementCSVPrint({
      rows: selectedItems,
      columns: headerColumns,
      data: _data,
      type: 'Pre',
    });

    if (success) {
      messageAlert('success', 'CSV Downloaded');
      setSelectedItems([]);
    }
  };

  React.useEffect(() => {
    refetch();
  }, [currentPage]);

  React.useEffect(() => {
    getTableData(_data);
  }, [_data]);

  React.useEffect(() => {
    handleErrorMessage(error);
  }, [error]);

  return {
    tabValue,
    params,
    currentPage,
    _data,
    isLoading,
    isRefetching,
    error,
    predisbursementTableOptions,
    predisbursementTableColumns,
    tableData,
    regions,
    districts,
    selectedItems,
    downloadCSV,
    refetch,
    setCurrentPage,
    setTabValue,
    setParams,
    filter,
  };
};
