/*eslint-disable */
import React, { useEffect, useState } from "react";
import { getLocalStorage } from "@utils/helpers/functions";
import propTypes from "prop-types";
import useRefreshToken from "@utils/hooks/useRefreshToken";
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";

export const checkPermissions = (permissionString, user) => {
  try {

    if (user?.staff_type == "super_admin"||  user?.role?.name == 'super_admin') {
      return true;
    }
    let { permissions: userPermissions } = user?.role;
    let found = false;
    userPermissions.every((permission) => {
      if (permission.name == permissionString) {
        found = true;
        return false;
      }
      return true;
    });

    return found;
  } catch (e) {
    console.log(e)
    return false;
  }
};

export const logout = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("user");
  return;
};

export default function RequireAuth({ children }) {
  const [refresh, setRefresh] = useState(1);
  useEffect(() => {
    useRefreshToken();
  }, [refresh]);
  setTimeout(function () {
    setRefresh(refresh + 1);
  }, 240000);

  let location = useLocation();
  const accessToken = getLocalStorage("accessToken");
  if (!accessToken) {
    return <Navigate to="/" state={{ from: location }} />;
  }
  return children;
}
RequireAuth.propTypes = {
  children: propTypes.node,
};
