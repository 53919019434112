/*eslint-disable */
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Scores from '@components/Scores';
import ApplicantInfo from '@components/ApplicantInfo';
import { getApplicant, approveApplicant } from '@service/Applicants';
import propTypes from 'prop-types';
import { Box, Button, Grid, Rating, CircularProgress } from '@mui/material';
import ApplicationOverview from './ApplicationOverview';
import Documents from './Documents';
import TrainingScoreForm from '@components/Score/TrainingScoreForm';
import { DefaultButton } from '@components/Buttons/ButtonFullWidth';
import AlertDialog from '@components/Modals';
import Remarks from '@components/Approvals/Remarks';
import { messageAlert } from '@utils/helpers/functions';
import { useNavigate } from 'react-router-dom';
import useGetUserData from '@utils/hooks/useGetUserData';
import ProfileLoader from '@components/Loaders/ProfileLoader';
import { makeEditable } from '@service/Applicants';
import { useMutation } from 'react-query';
import { handleErrorMessage } from '@utils/helpers/functions';
import { checkPermissions } from '@/service/RequireAuth';
import { BASE_CLIENT } from '@/service/apiConfig';
import TrainingScores from './TrainingScores';
import BusinessPlanComponent from './BusinessPlanComponent';

const ApplicantDetails = ({ selectedApplicant, type, category }) => {
  const [tabValue, setTabValue] = React.useState(type == 'training' ? 4 : 0);
  const [user, setUser] = React.useState(null);
  const [stars, setStars] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [profileLoading, setProfileLoading] = React.useState(false);
  const [remarks, setRemarks] = React.useState('');
  const userData = JSON.parse(window?.localStorage.getItem('user'));
  const editMutation = useMutation(makeEditable);
  // const { userData,isLoading:_isLoading } = useGetUserData();
  const navigate = useNavigate();

  var approvalText = 'applicants';
  switch (type) {
    case 'applicants':
      approvalText = 'Interview';
      break;
    case 'interview':
      approvalText = 'Training';
      break;
    case 'training':
      approvalText = 'Grant';
      break;
    default:
      approvalText = 'Interview';
  }

  React.useEffect(() => {
    if (selectedApplicant) fetchApplicant();
  }, [selectedApplicant]);

  const handleMakeEditable = async () => {
    editMutation.mutate(user?.data?.overview?.id, {
      onSuccess: () => {
        messageAlert('success', 'Account status changed');
      },
      onError: (e) => {
        messageAlert('error', 'Error with request');
      },
    });
  };

  const fetchApplicant = async () => {
    setProfileLoading(true);
    try {
      const response = await getApplicant(selectedApplicant);
      setUser(response.data);
      setStars(response?.data?.data?.application_score[0]?.score_rating);
    } catch (error) {
      let message = handleErrorMessage(error);
      messageAlert('error', message);
    }
    setProfileLoading(false);
  };

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };
  const style = {
    height: '100%',
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '3rem',
    paddingRight: '3rem',
  };
  const iconStyle = {
    borderRadius: '50%',
    backgroundColor: ' #ABB3BF',
    alignItems: 'center',
    justifyContent: 'center',
    width: '5rem',
    height: '5rem',
    fontWeight: '600',
    display: 'flex',
  };

  const [open, setOpen] = React.useState(false);
  const [openDisq, setOpenDisq] = React.useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setRemarks('');
  };

  const handleOpenDisqModal = () => {
    setOpenDisq(true);
  };

  const handleCloseDisqModal = () => {
    setOpenDisq(false);
  };

  const submitApproval = async (status, type, success, remarks = '', userObject = {}) => {
    let approvalData = {
      id_list: [{ id: user?.data?.overview?.id }],
      approval_status: status,
      approval_type: type,
    };
    if (type == 'interview_approval') {
      approvalData.remarks = remarks;
      approvalData.remarks_by = userObject;
    }
    setLoading(true);

    try {
      await approveApplicant(approvalData);
      messageAlert(status == 'approved' ? 'success' : 'error', success);
      setLoading(false);
      navigate(-1);
      setOpen(false);
    } catch (e) {
      let message = handleErrorMessage(e);
      messageAlert('error', message);
      setLoading(false);
    }
  };

  const remarksComponent = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box>Are you sure you want to approve Applicant(s) for {`${approvalText}`}?</Box>
        {type == 'interview' && (
          <Box mt={'1rem'}>
            <textarea
              placeholder={'Remarks'}
              style={{ width: '100%' }}
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            ></textarea>
          </Box>
        )}
      </Box>
    );
  };

  const statusComponent = (_status) => {
    let status = '';
    let style = {
      fontSize: '0.7rem',
      backgroundColor: '#ED1C2420',
      color: '#ED1C24',
      padding: '0.3rem',
      borderRadius: '0.3rem',
    };

    switch (_status) {
      case 'pending_application_approval':
        status = 'Pending Approval';
        style.backgroundColor = '#f9ffb8';
        style.color = '#838c01';
        break;
      case 'approved_application_approval':
        status = 'Approved for Interview';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_basic_training':
        status = 'Approved for Basic Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_intermediate_training':
        status = 'Approved for Intermediate Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_for_advanced_training':
        status = 'Approved for Advanced Training';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant':
        status = 'Approved for Grant Application';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant_pre_disbursement':
        status = 'Approved for Pre Disbursement';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'approved_application_grant_post_disbursement':
        status = 'Approved for Post Disbursement';
        style.backgroundColor = '#d7fccf';
        style.color = 'green';
        break;
      case 'disqualified':
        status = 'Disqualified';
        style.backgroundColor = '#f9ffb8';
        style.color = '#838c01';
        break;
      default:
        status = 'Pending';
    }

    return (
      <div className="application-status" style={style}>
        {status}
      </div>
    );
  };

  const ApproveButton = () => {
    return (
      <Button
        sx={{
          '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
          color: 'white',
          backgroundColor: '#2a3b8f',
          textTransform: 'initial !important',
          border: '1px solid #2a3b8f',
        }}
        onClick={() => {
          // setOpen(false);
          if (type == 'training') {
            submitApproval('approved', 'training_approval', 'Approved For Grant');
          }
          if (type == 'applicants') {
            submitApproval('approved', 'applicant_approval', 'Applicant Approved');
          }
          if (type == 'interview') {
            submitApproval('approved', 'interview_approval', 'Approved For Training', remarks, userData?.user);
          }
        }}
      >
        Approve
      </Button>
    );
  };

  const DisqButton = () => {
    return (
      <Button
        sx={{
          '&:hover': { color: '#2a3b8f', border: '1px solid #2a3b8f' },
          color: 'white',
          backgroundColor: '#2a3b8f',
          textTransform: 'initial !important',
          border: '1px solid #2a3b8f',
        }}
        onClick={() => {
          if (type == 'training') {
            submitApproval('disqualified', 'training_approval', 'Applicant Disqualified');
          }
          if (type == 'applicants') {
            submitApproval('disqualified', 'applicant_approval', 'Applicant Disqualified');
          }
          if (type == 'interview') {
            submitApproval('disqualified', 'interview_approval', 'Applicant Disqualified');
          }

          setOpenDisq(false);
        }}
      >
        Reject
      </Button>
    );
  };

  const printForm = (id) => {
    if (document !== null) {
      let a = window.open('', '', 'height=500, width=500');

      a.document.write('<html>');
      a.document.write('<head>');
      a.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">');
      // Include other necessary styles and scripts here
      a.document.write(`<style>
        body{
          width:100%;
          height:100vh;
          overflow-y:auto;
        }
      </style>`);
      a.document.write('</head>');
      a.document.write('<body>');
      // Create an iframe and set its source URL
      a.document.write(
        `<iframe src="${BASE_CLIENT}/grantassessment/print/${id}" id="iframe" width="100%" height="100%"></iframe>`,
      );
      a.document.write('</body></html>');
      setTimeout(function () {
        const iframe = a.document.getElementById('iframe');
        iframe?.contentWindow?.print();
        a.document.close();
      }, 5000);
      a.document.close();
    }
  };

  if (profileLoading == true) return <ProfileLoader />;

  return (
    <div style={style}>
      <div className="top flex flex-c">
        <div
          className="top flex"
          style={{
            borderBottom: '1px solid #e4e4e4',
            paddingBottom: '2rem',
            alignItems: 'center',
          }}
        >
          <div className="left flex" style={{ alignItems: 'center' }}>
            <div className="left" style={iconStyle}>
              <div style={{ color: 'white', fontSize: '2rem' }}>{user?.data?.overview?.first_name?.split('')[0]}</div>
            </div>
            <div style={{ marginRight: '1.5rem' }}>
              {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
            </div>
            <div className="right flex flex-c" style={{ justifyContent: 'center' }}>
              <div className="top flex" style={{ marginBottom: '0.5rem', fontWeight: '600' }}>
                <div
                  style={{
                    fontSize: '1.3rem',
                    marginRight: '0.6rem',
                    marginTop: '0.2rem',
                  }}
                >
                  {user?.data?.overview?.first_name} {user?.data?.overview?.last_name}
                </div>
                {statusComponent(user?.data?.client_details?.[0]?.application_process_status)}
                {/* <div className="application-status" style={{fontSize:'0.7rem',backgroundColor:'#ED1C2420',color:'#ED1C24',padding:'0.3rem',borderRadius:'0.3rem'}}>
                                Pending
                            </div> */}
              </div>
              <div className="bottom flex" style={{ alignItems: 'center' }}>
                <div>
                  <Rating name="user-rating" value={stars} readOnly={true} />
                </div>
                <div style={{ marginLeft: 'unset' }}>
                  {`(${
                    user?.data?.application_score[0]?.percentage_score
                      ? user?.data?.application_score[0]?.percentage_score
                      : 0
                  }%)`}
                </div>
              </div>
            </div>
          </div>
          <div className="right" style={{ marginLeft: 'auto', order: '1' }}>
            {type != undefined && type != null && (
              <div style={{ marginTop: '1rem' }}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Box
                      sx={{
                        width: '100%',
                        fontSize: '1.2rem',
                        fontWeight: '600',
                        marginBottom: '2rem',
                      }}
                    >
                      <DefaultButton
                        color="#FF4B55"
                        className="pull-right"
                        text="Reject"
                        onClick={() => {
                          handleOpenDisqModal();
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Box
                      sx={{
                        width: '100%',
                        fontSize: '1.2rem',
                        fontWeight: '600',
                        marginBottom: '2rem',
                      }}
                    >
                      <DefaultButton
                        className="pull-right"
                        text={`Approve For ${approvalText}`}
                        onClick={() => {
                          handleOpenModal();
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
            {(userData?.staff_type == 'super_admin' || userData?.role?.name == 'super_admin') && (
              <div style={{ textAlign: 'right', marginTop: '1rem' }}>
                <Button
                  sx={{
                    '&:hover': { color: 'white', backgroundColor: '#2a3b8f' },
                    fontWeight: '400',
                    fontFamily: 'inherit !important',
                    fontSize: '0.rem',
                    color: '#2a3b8f',
                    backgroundColor: 'white',
                    textTransform: 'initial !important',
                    border: '1px solid #2a3b8f',
                  }}
                  onClick={() => {
                    handleMakeEditable();
                  }}
                >
                  Make Applicant Editable
                </Button>
              </div>
            )}
            {checkPermissions('view grant assessment', userData) &&
              user?.data?.profile?.[0]?.is_business_plan_application_complete && (
                <div style={{ marginTop: '10px' }}>
                  <DefaultButton
                    onClick={() => {
                      printForm(user?.data?.overview?.id);
                    }}
                    text="Print Assessment Form"
                  />
                </div>
              )}
          </div>
        </div>
        <div className="bottom">
          {type !== 'training' && (
            <Tabs
              className="custom-tabs"
              value={tabValue}
              onChange={handleTabChange}
              style={{ borderBottom: '0.5px solid #EBEFF2' }}
              aria-label="basic tabs example"
            >
              <Tab label="Overview" value={0} />
              <Tab label="Scores" value={1} />
              <Tab label="Application Details" value={2} />
              <Tab label="Remarks" value={5} />
              <Tab label="Training Scores" value ={6}/>
              <Tab label="Grant Assessment" value ={7}/>
            </Tabs>
          )}
          {/* <Tab label="Documents" value={3}/> */}
          {type == 'training' && (
            <Tabs
              className="custom-tabs"
              value={tabValue}
              onChange={handleTabChange}
              style={{ borderBottom: '0.5px solid #EBEFF2' }}
              aria-label="basic tabs example"
            >
              <Tab label="Manual Scoring" value={4} />
            </Tabs>
          )}
        </div>
      </div>
      <div className="bottom" style={{ overflowY: 'auto', height: '100vh', overflowX: 'hidden' }}>
        {tabValue === 0 && (
          <div className="overview-area" style={{ height: '100%', marginBottom: '3rem' }}>
            <ApplicantInfo data={user} title="Personal Information" type="overview" />
            <ApplicantInfo data={user} title="Business Information" type="business" />
          </div>
        )}
        {tabValue === 1 && (
          <div className="scores-area" style={{ height: '100%', marginTop: '24px' }}>
            <Scores percentage={user?.data?.application_score[0]} />
          </div>
        )}
        {tabValue === 2 && (
          <div className="application-details">
            <ApplicationOverview userData={user} />
          </div>
        )}
        {tabValue === 3 && (
          <div className="document-list">
            <Documents userData={user} />
          </div>
        )}
        {tabValue === 4 && (
          <div className="training-score">
            <TrainingScoreForm />
          </div>
        )}
        {tabValue === 5 && (
          <div className="remarks">
            <Remarks user={user} />
          </div>
        )}
        {
          tabValue === 6 && (
            <div>
              <TrainingScores user={user}/>
            </div>
          )
        }
            {
          tabValue === 7 && (
            <div className='' style={{paddingTop:'1rem',paddingBottom:'2rem'}}>
              <BusinessPlanComponent/>
            </div>
          )
        }
      </div>

      {open && (
        <AlertDialog
          open={open}
          handleCloseModal={() => handleCloseModal(false)}
          title="Confirm Approval"
          body={remarksComponent()}
          actionButton={<ApproveButton />}
        />
      )}
      {openDisq && (
        <AlertDialog
          open={openDisq}
          handleCloseModal={() => handleCloseDisqModal(false)}
          title="Confirm Disqualification"
          body={`Are you sure you want to disqualify Applicant(s) for ${approvalText} ?`}
          actionButton={<DisqButton />}
        />
      )}
    </div>
  );
};

ApplicantDetails.propTypes = {
  selectedApplicant: propTypes.number,
};

export default ApplicantDetails;
