/*eslint-disable*/
import React from "react";
import {
  Grid,
  Box,
  Container,
  Button,
  Modal,
  Typography,
  FormLabel,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import DashboardSidebar from "@components/DashboardSidebar/DashboardSidebar";
import StaffNavbar from "../Staff/StaffNavbar";
import {
  handleErrorMessage,
  messageAlert,
  storeLocalStorage,
} from "@utils/helpers/functions";
import SettingsNav from "./SettingsNav";
import useGetUserData from "@utils/hooks/useGetUserData";
import { useForm, Controller } from "react-hook-form";
import UserSignUp from "@service/UserSignUp";
import ApplicantSidebar from "@components/ApplicantSidebar/ApplicantSidebar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";

const personalInformation = [
  { key: "is_female_score", label: "Applicant is Female" },
  { key: "is_disabled_score", label: "Applicant is a PWD" },
];

const educationalLevel = [
  { key: "has_no_formal_education_score", label: "No Formal Education" },
  { key: "has_jhs_certification_score", label: "JHS" },
  { key: "has_shs_certification_score", label: "SHS" },
  { key: "has_vocational_certification_score", label: "Vocational/Technical" },
  { key: "has_diploma_hnd_certification_score", label: "Diploma/HND" },
  { key: "has_degree_certification_score", label: "Degree" },
  { key: "has_masters_certification_score", label: "Masters" },
];

const trainingInformation = [
  {
    key: "has_previous_vocational_training_score",
    label: "Applicant has apprenticeship/skills training",
  },
  {
    key: "has_previous_vocational_training_certification_score",
    label: "Applicant has certification",
  },
  {
    key: "desires_entrepreneurship_score",
    label: "Applicant wants to start a business",
  },
  {
    key: "can_start_business_without_support_score",
    label: "If applicant can start a business without support",
  },
];

const entrepreneurialInformation1 = [
  {
    key: "desires_training_to_start_business_score",
    label:
      "Applicant is interested in learning practical or non-practical skills",
  },
  {
    key: "can_evaluate_business_idea_score",
    label: "Applicant knows how to evaluate their business idea",
  },
  {
    key: "operated_own_business_score",
    label: "Applicant has tried to start their own business",
  },
  {
    key: "operated_someones_business_score",
    label: "Applicant has worked or operated someone’s business before",
  },
  {
    key: "available_to_receive_entrepreneurial_training_score",
    label: "Applicant is able to receive entrepreneurial training",
  },
  { key: "has_business_plan_score", label: "Applicant has business plan" },
];

const entrepreneurialInformation2 = [
  {
    key: "has_created_business_plan_before_score",
    label: "Applicant has created business plan before",
  },
  {
    key: "understands_tax_requirements_score",
    label: "Applicant understand tax requirements",
  },
  { key: "owns_bank_account_score", label: "Applicant has a bank account" },
];

const AutomaticScoring = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { userData, isLoading } = useGetUserData();
  const [edit, setEdit] = React.useState(false);
  const [automaticScores, setAutomaticScores] = React.useState(null);
  const navigate = useNavigate();

  const getAutomaticScore = async () => {
    try {
      let { data } = await UserSignUp.getAutomaticScore();
      setAutomaticScores(data);
    } catch (e) {

    }
  };

  const onSubmit = async (data) => {
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        data[key] = automaticScores[key];
      }
    });
    try {
      await UserSignUp.createAutomaticScore(data);
      messageAlert("success", "Scores Saved");
      await getAutomaticScore();
      setEdit(false);
    } catch (e) {
      let message = handleErrorMessage(e);
      messageAlert("error", message);

    }
  };

  const checkRequired = (field, watchValue) => {
    if (
      (watchValue === "" || watchValue === undefined) &&
      (field === undefined || field === null)
    ) {
      return true;
    }
    if (
      (watchValue !== "" || watchValue !== undefined) &&
      (field === undefined || field === null)
    ) {
      return false;
    }
    if (
      (watchValue !== "" || watchValue !== undefined) &&
      (field !== undefined || field !== null)
    ) {
      if (watchValue === "") {
        return true;
      }
      return false;
    }

    return true;
  };

  const handleTabChange = (event, value) => {};

  React.useEffect(() => {
    document.body.style.cssText = "overflow-y:hidden!important";
    getAutomaticScore();
  }, []);

  React.useEffect(() => {
    if (isLoading == false) {
      
      let status = userData?.staff_type == "super_admin"||userData?.role?.name == 'super_admin';
      if (status == false) {
        navigate("/404");
      }
    }
  }, [userData]);

  return (
    <Box
      className="staff-main"
      sx={{ width: "100%", height: "100vh", overflowY: "hidden" }}
    >
      <Grid sx={{ width: "100%", height: "100vh", display: "flex" }}>
        <ApplicantSidebar selectedView={3} />
        <Grid sx={{ display: "flex", flexDirection: "column", width: "98%" }}>
          <StaffNavbar user={userData?.user} />
          <Grid container sx={{ overflowY: "auto" }}>
            <SettingsNav selectedView={1} />
            <Grid
              item
              xs={9}
              sx={{ display: "flex", flexDirection: "column", padding: "2rem" }}
            >
              <Box sx={{ marginBottom: "1rem" }}>
                <Tabs
                  className="custom-tabs"
                  value={0}
                  onChange={handleTabChange}
                  style={{
                    borderBottom: "0.5px solid #EBEFF2",
                    borderTop: "0.5px solid #EBEFF2",
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab label="Automatic Scoring" value={0} />
                </Tabs>
              </Box>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {/* <Box sx={{fontSize:'1.2rem',fontWeight:'600'}}>Automatic Score</Box> */}
                  <Box sx={{ fontSize: "0.8rem" }} my={"1rem"}>
                    The automatic score is used for evaluating and approving
                    applicants
                  </Box>
                </Box>
                <Box sx={{ order: "1", marginLeft: "auto" }}>
                  <Button
                    onClick={() => {
                      if (!edit) {
                        setEdit(!edit);
                        //do nothing for now
                      } else {
                        handleSubmit(onSubmit)();
                      }
                    }}
                    sx={{
                      "&:hover": {
                        color: "#2a3b8f",
                        border: "1px solid #2a3b8f",
                      },
                      fontWeight: "400",
                      fontFamily: "inherit !important",
                      fontSize: "0.85rem",
                      color: "white",
                      backgroundColor: "#2a3b8f",
                      textTransform: "initial !important",
                      padding: "0.7rem",
                      paddingLeft: "2rem",
                      paddingRight: "2rem",
                    }}
                  >
                    {edit === false ? "Edit Scores" : "Save"}
                  </Button>
                </Box>
              </Box>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    my={"1rem"}
                    mt={"1rem"}
                    sx={{
                      fontSize: "1.0rem",
                      color: "#2A3B8F",
                      borderLeft: "3px solid",
                      fontWeight: "600",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Personal Information
                  </Box>
                  <Grid
                    container
                    sx={{ width: "100%" }}
                    columnSpacing={"1.5rem"}
                    rowSpacing={"1rem"}
                  >
                    {personalInformation.map((field, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Box sx={{ marginBottom: "0.5rem", height: "1rem" }}>
                            {field.label}
                          </Box>
                          <Box>
                            <Box mt={"1rem"}>
                              <input
                                defaultValue={automaticScores?.[`${field.key}`]}
                                min="0"
                                type="number"
                                style={{ width: "calc(100%-1.6rem)" }}
                                placeholder="Score"
                                className="add-staff-form-input"
                                {...register(field?.key, {
                                  required: checkRequired(
                                    automaticScores?.[`${field.key}`],
                                    watch(`${field.key}`)
                                  ),
                                })}
                                disabled={!edit}
                              ></input>
                            </Box>
                          </Box>
                          {errors[`${field.key}`] && (
                            <span style={{ marginTop: "1rem", color: "red" }}>
                              This field is required
                            </span>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box
                    my={"1rem"}
                    mt={"3rem"}
                    sx={{
                      fontSize: "1.0rem",
                      color: "#2A3B8F",
                      borderLeft: "3px solid",
                      fontWeight: "600",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Educational level
                  </Box>
                  <Grid
                    container
                    sx={{ width: "100%" }}
                    columnSpacing={"1.5rem"}
                    rowSpacing={"1rem"}
                  >
                    {educationalLevel.map((field, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Box sx={{ marginBottom: "0.5rem", height: "1rem" }}>
                            {field.label}
                          </Box>
                          <Box>
                            <Box mt={"1rem"}>
                              <input
                                defaultValue={automaticScores?.[`${field.key}`]}
                                min="0"
                                type="number"
                                style={{ width: "calc(100%-1.6rem)" }}
                                placeholder="Score"
                                className="add-staff-form-input"
                                {...register(field?.key, {
                                  required: checkRequired(
                                    automaticScores?.[`${field.key}`],
                                    watch(`${field.key}`)
                                  ),
                                })}
                                disabled={!edit}
                              ></input>
                            </Box>
                          </Box>
                          {errors[`${field.key}`] && (
                            <span style={{ marginTop: "1rem", color: "red" }}>
                              This field is required
                            </span>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box
                    my={"1rem"}
                    mt={"3rem"}
                    sx={{
                      fontSize: "1.0rem",
                      color: "#2A3B8F",
                      borderLeft: "3px solid",
                      fontWeight: "600",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Training Information
                  </Box>
                  <Grid
                    container
                    sx={{ width: "100%" }}
                    columnSpacing={"1.5rem"}
                    rowSpacing={"1rem"}
                  >
                    {trainingInformation.map((field, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Box sx={{ marginBottom: "0.5rem", height: "1rem" }}>
                            {field.label}
                          </Box>
                          <Box>
                            <Box mt={"1rem"}>
                              <input
                                defaultValue={automaticScores?.[`${field.key}`]}
                                min="0"
                                type="number"
                                style={{ width: "calc(100%-1.6rem)" }}
                                placeholder="Score"
                                className="add-staff-form-input"
                                {...register(field?.key, {
                                  required: checkRequired(
                                    automaticScores?.[`${field.key}`],
                                    watch(`${field.key}`)
                                  ),
                                })}
                                disabled={!edit}
                              ></input>
                            </Box>
                          </Box>
                          {errors[`${field.key}`] && (
                            <span style={{ marginTop: "1rem", color: "red" }}>
                              This field is required
                            </span>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box
                    my={"1rem"}
                    mt={"3rem"}
                    sx={{
                      fontSize: "1.0rem",
                      color: "#2A3B8F",
                      borderLeft: "3px solid",
                      fontWeight: "600",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Entrepreneurial/Business Acumen
                  </Box>
                  <Grid
                    container
                    sx={{ width: "100%" }}
                    columnSpacing={"1.5rem"}
                    rowSpacing={"1rem"}
                  >
                    {entrepreneurialInformation1.map((field, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Box sx={{ marginBottom: "0.5rem", height: "1rem" }}>
                            {field.label}
                          </Box>
                          <Box>
                            <Box mt={"1rem"}>
                              <input
                                defaultValue={automaticScores?.[`${field.key}`]}
                                min="0"
                                type="number"
                                style={{ width: "calc(100%-1.6rem)" }}
                                placeholder="Score"
                                className="add-staff-form-input"
                                {...register(field?.key, {
                                  required: checkRequired(
                                    automaticScores?.[`${field.key}`],
                                    watch(`${field.key}`)
                                  ),
                                })}
                                disabled={!edit}
                              ></input>
                            </Box>
                          </Box>
                          {errors[`${field.key}`] && (
                            <span style={{ marginTop: "1rem", color: "red" }}>
                              This field is required
                            </span>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>

                  <Box
                    my={"1rem"}
                    mt={"3rem"}
                    sx={{
                      fontSize: "1.0rem",
                      color: "#2A3B8F",
                      borderLeft: "3px solid",
                      fontWeight: "600",
                      paddingLeft: "0.5rem",
                    }}
                  >
                    Entrepreneurial/Business Acumen
                  </Box>
                  <Grid
                    container
                    sx={{ width: "100%" }}
                    columnSpacing={"1.5rem"}
                    rowSpacing={"1rem"}
                  >
                    {entrepreneurialInformation2.map((field, index) => {
                      return (
                        <Grid
                          item
                          xs={4}
                          sx={{ display: "flex", flexDirection: "column" }}
                        >
                          <Box sx={{ marginBottom: "0.5rem", height: "1rem" }}>
                            {field.label}
                          </Box>
                          <Box>
                            <Box mt={"1rem"}>
                              <input
                                defaultValue={automaticScores?.[`${field.key}`]}
                                min="0"
                                type="number"
                                style={{ width: "calc(100%-1.6rem)" }}
                                placeholder="Score"
                                className="add-staff-form-input"
                                {...register(field?.key, {
                                  required: checkRequired(
                                    automaticScores?.[`${field.key}`],
                                    watch(`${field.key}`)
                                  ),
                                })}
                                disabled={!edit}
                              ></input>
                            </Box>
                          </Box>
                          {errors[`${field.key}`] && (
                            <span style={{ marginTop: "1rem", color: "red" }}>
                              *This field is required
                            </span>
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AutomaticScoring;
