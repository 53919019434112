/*eslint-disable*/
import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import { checkPermissions } from '@service/RequireAuth';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import DisbursalMenu from './Submenus/DisbursalMenu';
import ShowPermissions from '../Users/ShowPermissions';
import AssessmentMenu from './Submenus/AssessmentMenu';

const drawerStyles = {
  backgroundColor: '#293885',
  color: 'white',
  width: '320px',
  height: '100%',
};

const navItemStyle = {
  color: 'white',
  fontSize: '1rem',
  backgroundColor: 'inherit',
  marginLeft: '30%',
  paddingBottom: '1.6rem',
  '&:hover': {
    cursor: 'pointer',
    color: '#ced4da',
  },
};

const ApplicantDrawer = ({ open, onclose, userData }) => {
  const navigate = useNavigate();
  const [reportsAnchor, setReportsAnchor] = React.useState(null);
  const [anchor, setAnchor] = React.useState(null);
  const [prefMenuAnchor, setPrefMenuAnchor] = React.useState(null);
  const [disbursalMenuAnchor, setDisbursalMenuAnchor] = React.useState(null);
  const [assessmentMenuAnchor, setAssessmentMenuAnchor] = React.useState(null);
  const prefMenuOpen = Boolean(prefMenuAnchor);
  const reportsOpen = Boolean(reportsAnchor);

  const handleReportsMenuSelect = (event) => {
    if (reportsOpen) {
      setReportsAnchor(null);
    } else setReportsAnchor(event.currentTarget);
  };
  const handlePrefMenuSelect = (event) => {
    if (prefMenuOpen) {
      setPrefMenuAnchor(null);
    } else {
      setPrefMenuAnchor(event.currentTarget);
    }
  };
  const menuOpen = Boolean(anchor);
  const handleMenuSelect = (event) => {
    if (menuOpen) {
      handleClose();
    } else setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  return (
    <Drawer anchor="left" open={open} sx={drawerStyles} hideBackdrop={true}>
      <DisbursalMenu menuAnchor={disbursalMenuAnchor} setMenuAnchor={setDisbursalMenuAnchor} />
      <AssessmentMenu menuAnchor={assessmentMenuAnchor} setMenuAnchor={setAssessmentMenuAnchor} />

      <Menu
        id="basic-menu"
        anchorEl={reportsAnchor}
        open={reportsOpen}
        onClose={() => {
          setReportsAnchor(null);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            color: '#2A3B8F',
            backgroundColor: 'white',
            paddingLeft: '0.5rem',
            boxShadow: 1,
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <ShowPermissions shouldShow={checkPermissions('data analysis', userData) == true}>
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/reports/dataAnalysis');
            }}
          >
            Data Analysis
          </MenuItem>
        </ShowPermissions>

        <MenuItem
          sx={{ fontFamily: 'inherit!important' }}
          onClick={() => {
            navigate('/reports/detailedReports');
          }}
        >
          Detailed Reports
        </MenuItem>
        <MenuItem
          sx={{
            fontFamily: 'inherit!important',
            color: 'red',
            display: 'flex',
          }}
          onClick={() => {
            setReportsAnchor(null);
          }}
        >
          <Box
            sx={{
              fontFamily: 'inherit!important',
              color: 'red',
              display: 'flex',
              fontSize: '1rem',
            }}
          >
            Close Menu
          </Box>
          <Box sx={{ order: '1', marginLeft: 'auto' }}>
            <CloseIcon sx={{ color: 'red', marginLeft: '1rem' }} />
          </Box>
        </MenuItem>
      </Menu>
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={menuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            color: '#2A3B8F',
            backgroundColor: 'white',
            boxShadow: 1,
            zIndex: '1000000000000000000000000',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {checkPermissions('applicant approvals', userData) == true && (
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/applicant/approvals');
            }}
          >
            Application Approvals
          </MenuItem>
        )}

        {checkPermissions('interview approvals', userData) == true && (
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/interview/approvals');
            }}
          >
            Interview/Screening Approvals
          </MenuItem>
        )}

        {checkPermissions('training approvals', userData) == true && (
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/training/approvals');
            }}
          >
            Training Approvals
          </MenuItem>
        )}

        <ShowPermissions shouldShow={checkPermissions('training approvals', userData)}>
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/grant/approvals/regular');
            }}
          >
            Grant Approvals
          </MenuItem>
        </ShowPermissions>

        <MenuItem
          sx={{ fontFamily: 'inherit!important', color: 'red' }}
          onClick={() => {
            handleClose();
          }}
        >
          {' '}
          Close Menu <CloseIcon sx={{ color: 'red', marginLeft: '1rem' }} />
        </MenuItem>
      </Menu>

      {(userData?.staff_type == 'super_admin' || userData?.role?.name == 'super_admin') && (
        <Menu
          id="basic-menu"
          anchorEl={prefMenuAnchor}
          open={prefMenuOpen}
          onClose={() => {
            setPrefMenuAnchor(null);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            '& .MuiPaper-root': {
              color: '#2A3B8F',
              backgroundColor: 'white',
              boxShadow: 1,
              zIndex: '1000000000000000000000000',
            },
          }}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/settings/roles');
            }}
          >
            User Management
          </MenuItem>
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/settings/automaticScoring');
            }}
          >
            Settings
          </MenuItem>
          <MenuItem
            sx={{ fontFamily: 'inherit!important' }}
            onClick={() => {
              navigate('/settings/auditTrail');
            }}
          >
            Audit Trail
          </MenuItem>
          <MenuItem
            sx={{ fontFamily: 'inherit!important', color: 'red' }}
            onClick={() => {
              setPrefMenuAnchor(null);
            }}
          >
            {' '}
            Close Menu <CloseIcon sx={{ color: 'red', marginLeft: '1rem' }} />
          </MenuItem>
        </Menu>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '320px',
          backgroundColor: '#293885',
          height: '100%',
          paddingTop: '7.25rem',
        }}
      >
        <Box
          sx={navItemStyle}
          onClick={() => {
            onclose(false);
          }}
        >
          Close Sidebar
        </Box>
        {(checkPermissions('registrations', userData) == true ||
          checkPermissions('applications', userData) == true) && (
          <Box
            sx={navItemStyle}
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            Dashboard
          </Box>
        )}
        <Box
          sx={navItemStyle}
          onClick={() => {
            navigate('/applicants');
          }}
        >
          Applicants
        </Box>

        {(checkPermissions('applicant approvals', userData) == true ||
          checkPermissions('training approvals', userData) == true ||
          checkPermissions('interview approvals', userData) == true ||
          checkPermissions('grant approvals', userData)) && (
          <Box sx={navItemStyle} onClick={handleMenuSelect}>
            Approvals
          </Box>
        )}

        <ShowPermissions shouldShow={checkPermissions('view grant assessment', userData)}>
          <Box
            sx={navItemStyle}
            onClick={(e) => {
              setAssessmentMenuAnchor(e.target);
            }}
          >
            Grant Assessment
          </Box>
        </ShowPermissions>

        <ShowPermissions
          shouldShow={
            (checkPermissions('pre disbursement', userData) &&
              checkPermissions('post disbursement', userData) &&
              checkPermissions('disbursement approvals', userData)) == true
          }
        >
          <Box
            sx={navItemStyle}
            onClick={() => {
              setDisbursalMenuAnchor(event.target);
            }}
          >
            Disbursement
          </Box>
        </ShowPermissions>
        <ShowPermissions
          shouldShow={checkPermissions('data analysis', userData) || checkPermissions('detailed report', userData)}
        >
          <Box
            sx={navItemStyle}
            onClick={(e) => {
              handleReportsMenuSelect(e);
            }}
          >
            Reports
          </Box>
        </ShowPermissions>

        {userData?.staff_type === 'super_admin' && (
          <Box sx={navItemStyle} onClick={handlePrefMenuSelect}>
            Preferences
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default ApplicantDrawer;
